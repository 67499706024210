import { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Container, Row, Col, Dropdown } from 'react-bootstrap';
import { userService } from '../../_services';
import { Link, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { JitsiMeeting } from '@jitsi/web-sdk';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import firebase from "../firebase";
import { getMessaging ,onMessage} from "firebase/messaging";
import { socket } from '../../_services/socketio';
import { IMAGE_REGEX, LOGIN_HEADER_MENU } from '../../config/constants';
import { jitsiConfig } from '../../config/config';
import { setItems, getItems } from '../../utils/localStorage/index';
import { returnFeatureByPlanId } from '../../utils/commonFunction';
import ProfileImage from '../../images/profile.png';
// BADGES ICON
import ConsumerBadge from '../../images/consumer_badge.png';
import GoldBadge from '../../images/gold_badge.svg';
import SilverBadge from '../../images/silver_badge.svg';
import BronzeBadge from '../../images/bronze_badge.svg';
import DiamondBadge from '../../images/diamond_badge.png';


const LoginHeader = () => {
  const [userName, setUserName] = useState('') as any;
  const [profile, setProfile] = useState('') as any;
  const [badgeCount, setBadgeCount] = useState('') as any;
  const [metricCount, setMetricCount] = useState('') as any;

  const [showJitsiModal, setJitsiModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [otherUser, setOtherUser] = useState({}) as any;
  const [jitsiRoom, setJitsiRoom] = useState("");
  const [shareData, setShareData] = useState({}) as any ;
  const [shareModal, setShareModal] = useState(false);
  const [isNotifcationViewed, setIsNotifcationViewed] = useState(true);
  const [shareURL, setShareURL] = useState({}) as any;
  const [timerStatus, setTimerStatus] = useState(false);
  const [timer, setTimer] = useState(20);
  const [badgeUrl, setBadgeUrl] = useState('') as any;

  // const [planDetail, setPlanDetail] = useState({}) as any;
  const [isPlan, setIsPlan] = useState(false);



  const chatSocket = useRef(null) as any;
  const history = useHistory();

  const apiRef = useRef(null);
  const search = new URLSearchParams(window.location.search);
  let plan_id = search.get("plan_id") as any;

  let user_id = localStorage.getItem("user_id");

  const messaging = getMessaging(firebase);
  console.log('\n messaging...',messaging);

  // check if notification is enabled in system
  const isSupported=localStorage.getItem("isSupported") as any;

  const logout = () => {
    let params = {
      "deviceType": "W",
      "device_token": localStorage.getItem('firebaseToken') as any,
      "unique_device_id": localStorage.getItem('udid') as any
    };

    if (isSupported === "true") {
      userService.logout(params).then(function (response) {
        localStorage.clear();
        window.location.href = "/login";
      }).catch(error => {
        toast.error('Something went wrong');
      });
    } else {
      localStorage.clear();
      window.location.href = "/login";
    }
  }

  useEffect(() => {
    let notif_badge = localStorage.getItem("notif_badge") as any;

    console.log('notif_badge',notif_badge)
    let username = localStorage.getItem('username');
    let profileImage = localStorage.getItem('profileImage');
    let count = localStorage.getItem("metricBadgeCount");
    let plan = getItems('plan_details');
    console.log('useEffect plan...', plan);
    if (notif_badge) {
      notif_badge = Number(notif_badge);
      setBadgeCount(notif_badge);
    } else {
      setBadgeCount('');
    }
    if (count) {
      setMetricCount(count);
    } else {
      setMetricCount('');
    }
    setUserName(username);
    setProfile(profileImage);
    if (window.location.pathname === "/membership/plan" || window.location.pathname === "/create_profile" || window.location.pathname === "/payment") {

    } else {
      getUserProfile();
    }

    if (plan) {
      setIsPlan(true);
    } else {
      setIsPlan(false);
    }

    // const accesstoken = localStorage.getItem('accesstoken');
    // const userId = localStorage.getItem('userId');
    // if (accesstoken) {
    //   userService.userdetail(accesstoken).then((response) => {
    //   }).catch((e) => {
    //     if (e.status == 401) {
    //       window.location.href = "/login"
    //       localStorage.removeItem('accesstoken');
    //       localStorage.removeItem('user_id');
    //       localStorage.removeItem('username');
    //       localStorage.removeItem('profileImage');
    //     }
    //   })
    // }

    // socket.current = io(`${config.chatSocket}`, {
    //   reconnection: true,
    //   reconnectionDelay: 1000,
    //   reconnectionDelayMax: 5000,
    //   reconnectionAttempts: Infinity
    // }) as any;

    chatSocket.current = socket;
    chatSocket.current.emit("joinroom", `VCR-${user_id}`);
  }, [])


  // Clearing All Notification when user is on notification
  useEffect(() => {
    if (window.location.pathname === '/notification') {
      if (isNotifcationViewed) {
        setBadgeCount('');
        localStorage.setItem("notif_badge", "");
        setIsNotifcationViewed(false);
      }
    }
    if (window.location.pathname === '/alert') {
      if (isNotifcationViewed) {
        localStorage.setItem("metricBadgeCount", '');
        setMetricCount('');
        setIsNotifcationViewed(false);
      }

    }
  }, [isNotifcationViewed])

  if (isSupported === "true") {
    // const messaging = firebase.messaging()
    onMessage(messaging,(data: any) => {
      let notif = data.data;
      let user_id = localStorage.getItem("user_id");
      let label = notif.label;
      var winURL = window.location.href;
      // var receiver_id = notif.receiverInfo?.receiver_id;
      console.log('notif',notif)
      let newBadge = JSON.parse(notif?.data)
      var redirect = `/friendchat?room=${btoa(notif.group_id)}&uid=${btoa(notif.sender_id)}`;
      var badge = newBadge.badge as any;
     
     

      if (notif.user_id === user_id) {
        if (label === "alert") {
          if (notif.msg.includes("the warning state.")) {
            toast.info(notif.msg, {
              position: "top-right",
              autoClose: 7000,
              onClick: () => loadModal(notif)
            });
            toggleSound();
            // {...toastConfig, onClick: (e: any) => handleClick(notif) }
          }
          if (notif.msg.includes("the critical state.")) {
            toast.info(notif.msg, {
              position: "top-right",
              autoClose: 7000,
              onClick: () => loadModal(notif)
            });
            toggleSound();
          }
        }
      }

      console.log('data.data', data.data);
      let notification = JSON.parse(notif?.data)
   console.log('notif',notification.receiver_id)
      // toast.info(data.data.msg, { onClick: (e: any) => handleClick(notif) });
      console.log('receiver_id == user_id', notification.receiver_id == user_id, '\n receiver_id ', notification.receiver_id, '\n user_id', user_id, 'notif.receiver_id', notif.receiver_id)
      if (notification.receiver_id == user_id) {
        // toast.dismiss(); 
        if (!isNaN(badge)) {
          // badge = Number(badge) + 1;
          badge = Number(badge) + 1;
          localStorage.setItem("notif_badge", badge);
          setBadgeCount(badge);
        }
        if (!winURL.includes(redirect) && label == 'new_message') {
          console.log('notification',notification)
    // notification['label'] = "new_message"
          toast.info(notif.msg, { onClick: (e: any) => handleClick(notification) });
        }
        if (label == "start_videocall") {
          toast.info(notif.msg);
          setJitsiRoom(notification.room_id);
          let callData = {
            room_id: notification.room_id,
            caller_name: notification.sender_name,
            receiver_name: notification.receiver_name,
            calltype: notification.calltype
          }
          console.log('callData',callData)
          setOtherUser(callData);
          setShowModal(true);
        } else {

          if (label == "end_videocall") {
            if (notification.calltype == 'S') {
              toast.info(notif.msg);
            }
          } else {
            
            if (label != 'new_message') {
     
              toast.info(notif.msg, { onClick: (e: any) => handleClick(notification) });
            }
          }
        }
        if (label === "payment_failed") {
          toast.info(notif.msg);
        }
      } else {
        if (!isNaN(badge)) {
          // badge = Number(badge) + 1;
          badge = Number(badge) + 1;
          localStorage.setItem("metricBadgeCount", badge);
          setMetricCount(badge);
        }
      }
      // DO NOT REMOVE CODE
      //  else if (JSON.parse(notif.receiver_id).member_id == user_id) {
      //   if (!isNaN(badge)) {
      //     badge = Number(badge) + 1;
      //     localStorage.setItem("notif_badge", badge);
      //     debugger
      //     setBadgeCount(badge);
      //   }
      //   var group_redirect = `/group_chat?id=${notif.group_id}`;
      //   let status = window.location.href.includes(group_redirect);
      //   if (!status) {
      //     toast.info(notif.msg, { onClick: (e: any) => handleClick(notif) })
      //   }
      // }
    })
  }


  // API for get user profile
  function getUserProfile() {
    let accesstoken = localStorage.getItem('accesstoken');
    userService.userdetail(accesstoken).then((response) => {
      if (response.data.data.business_status) {
        setItems('business_status', response.data.data.business_status);
        if (response.data.data.business_status === "3") {
          localStorage.setItem('memberShip_key', '');
          localStorage.setItem('membershipDuration', '');
        }
      }
      if (response.data.data.plan_details) {
        if (response.data.data.plan_details.plan_id === 3) {
          if (response.data.data.refferal_count < 1000) {
            setBadgeUrl(BronzeBadge);
          }
          if (response.data.data.refferal_count >= 1000 && response.data.data.refferal_count <= 5000) {
            setBadgeUrl(SilverBadge);
          }
          if (response.data.data.refferal_count > 5000) {
            setBadgeUrl(DiamondBadge);
          }
        } else {
          let url = returnUrlofBadge(response.data.data.plan_details.plan.toLowerCase()) as any;
          setBadgeUrl(url);
        }
        setIsPlan(true);
        let activeFeature = returnFeatureByPlanId(response.data.data.plan_details.plan_id);
        if (activeFeature) {
          setItems('plan_features', activeFeature);
        }
        if (response.data.data.associate_status) {
          localStorage.setItem('associate_status', response.data.data.associate_status);
        } else {
          localStorage.setItem('associate_status', '');
        }
      } else {
        history.push('/membership/plan');
        // window.location.href ="";
      }
    }).catch((error: any) => {
      if (error.response.status == 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    });
  }

  function loadModal(n: any) {
    localStorage.setItem('activeMetric', JSON.stringify(n));
    history.push('/alert')
  }


  function handleClick(n: any) {
    // const user_id = localStorage.getItem('user_id');
    // userService.markRead(user_id).then(response => {
    // }).catch(error => {
    //   console.log('error...', error)
    // })
    
  
    redirectNotifaction(n);
  }

  function redirectNotifaction(n: any) {
    let label = n.label;
    console.log("friendNotifiParse",n)
    let metricType = n.metric_type;
    if (label == 'new_message') {
      localStorage.setItem('otherFriendChat', n.sender_name);
      redirectToPage(`/friendchat?room=${btoa(n.group_id)}&uid=${btoa(n.sender_id)}`);
    }
    if (label == 'group_message') {
      redirectToPage(`/group_chat?id=${n.group_id}`);
    }
    if (label == 'group_invitation') {
      redirectToPage(`/join_group?id=${n.group_id}`);
    }
    if (label == 'group_join' || label == "group_member_added") {
      redirectToPage(`/invite_group?id=${n.group_id}`);
    }
    if (label == 'keep_great_work_metric' || label == 'prescription_reminder') {
      localStorage.removeItem("record");
      localStorage.removeItem("prescription");
      redirectToPage('/');
    }
    if (label == 'update_information_notification' || label == 'last_year_no_updation') {
      localStorage.removeItem("prescription");
      localStorage.setItem("record", 'yes');
      redirectToPage('/');
    }
    if (label == 'reject_request') {
      redirectToPage(`/sendfriendrequest?uid=${btoa(n.sender_id)}`);
    }
    if (label == 'friend_request') {
    
      redirectToPage(`/friendrequest?uid=${btoa(n.sender_id)}`);
    }
    if (label == 'accept_request') {
      redirectToPage(`/viewuser?uid=${btoa(n.sender_id)}`);
    }
    if (label == 'sharing_notification') {
      setShareData(n)
      // console.log("nnnn",n)
      setShareModal(true);
      toggleTimer(true);
      setTimer(n?.sharing_time);
      localStorage.setItem('notification_timer', n?.sharing_time);
      localStorage.setItem('competition_id', n?.competition_id);
      localStorage.setItem('notification_timer_status', 'start');
    }
    if (label == 'keep_great_work_share' || label == 'competition_winner') {
      if (n.competition_id) {
        redirectToPage(`/leaderboard?comp_id=${n.competition_id}`);
      } else {
        redirectToPage(`/leaderboard?comp_id=${n.group_id}`);
      }
    }
    if (label == 'wellness_notification' || label == 'start_competition' || label == 'competition_last_updation') {
      redirectToPage(`/competitiondetails?comp_id=${n.competition_id}`);
    }
    if (label == 'competition_invite' || label == 'referral_competition_invitation') {
      redirectToPage(`/live_competition?comp_id=${n.comp_id}`);
    }
    if (label === "alert") {
      history.push("/alert");
      // redirectToPage("/alert");
    } else {
      if (metricType) {
        if (metricType == "1") {
          redirectToPage('/bodygraph');
        }
        if (metricType == "2") {
          redirectToPage('/bloodgraph');
        }
        if (metricType == "3") {
          redirectToPage('/lifegraph');
        }
        else {
          redirectToPage('/moodgraph');
        }
      }
    }
    if (label == 'Survey' || label == 'Web') {
      let url = "";
      if (n.message) {
        url = n.message.split("-")[1];
      } else {
        url = n.url;
      }
      redirectToPage(url);
    }
    if(label === "Group Invitation Request"){
      setItems('group_id',n.competition_id)
      redirectToPage(`/viewrequest`);
    }
  }

  function redirectToPage(url: any) {
    window.location.href = url;
  }

  function acceptCall() {
    localStorage.setItem("otherFriendChat", otherUser.caller_name);
    let acptData = {
      "caller_id": user_id,
      "call_event": "acceptcall",
      "room_id": otherUser.room_id
    }
    console.log('acceptCall',acptData)
    chatSocket.current.emit("acceptcall", acptData);
    chatSocket.current.on("acceptcall", (res: any) => {
      console.log("chatSocket",res);
    })
    setShowModal(false);
    setJitsiRoom(otherUser.room_id);
    setJitsiModal(true);
  }

  function rejectCall() {
    setShowModal(false)
    let sData = {
      "room_id": otherUser.room_id,
      "caller_id": localStorage.getItem("user_id"),
      "calltype": otherUser.calltype,
      "call_event": "rejectcall"
    };
    chatSocket.current.emit("rejectcall", sData);
  }

  if (chatSocket.current) {
    chatSocket.current.on("endcall", (resp: any) => {
      if (otherUser.calltype == "S") {
        setJitsiModal(false);
        setJitsiRoom("");
        setOtherUser({});
      }
      if (otherUser.calltype == "G") {
        setJitsiModal(false);
        setJitsiRoom("");
        setOtherUser({});
      }
    });
    chatSocket.current.on("getCalls", (res: any) => {
      setJitsiRoom(res.room_id);
      setOtherUser(res);
      setShowModal(true);
    });
    chatSocket.current.on("rejectcall", (res: any) => {
      console.log(res, '>>>>>>>>>>>>>>>>>...');
    });
  }

  function handleApiReady(apiObj: any, ref: any) {
    ref.current = apiObj;
    ref.current.addEventListener("readyToClose", function (props: any) {

      if (otherUser.calltype == 'S' || otherUser.calltype == 'G') {
        chatSocket.current.emit("endcall", { room_id: jitsiRoom, calltype: otherUser.calltype, caller_id: user_id, call_event: "End Call" })
        chatSocket.current.on("endcall", (resp: any) => {
          if (resp.call_status == 1) {
            setJitsiRoom("");
            setJitsiModal(false);
          }
        })
      }
    })

  }

  const handleJitsiIFrameRef = (iframeRef: any) => {
    iframeRef.style.border = '10px solid cadetblue';
    iframeRef.style.background = 'cadetblue';
    iframeRef.style.height = '600px';
  };

  function handleTimerState(data: number) {
    if (data === 0) {
      setTimer(0);
      setShareModal(false);
    }
  }

  function readTime(t: any) {
    localStorage.setItem('notification_timer', t.remainingTime);
    handleTimerState(parseFloat(t.remainingTime));
    return (
      <div><h1>{t.remainingTime}</h1></div>
    )
  }

  function socialShare(p: any, time: any) {
    let params = {
      "share_type": p,
      "competition_id": String(shareData.competition_id),
      "time": time,
    };
    userService.shareAd(params).then(resp => {
      toggleTimer(true);
    }).catch(err => console.log(err))
  }

  // common function for social media share URL
  function handleSocialShareUrl(url: any) {
    setShareURL((prevShareURL: any) => {
      return {
        ...prevShareURL,
        ...url
      }
    });
  }

  // change local storage value to keep track of particular events
  function handleMenuClick() {
    localStorage.setItem('viaIsDetail', "false");
    localStorage.setItem('viaInviteGroup', "false");
    localStorage.setItem('viaNotification', "false");
    localStorage.setItem('discount_code', '');
  }

  function toggleTimer(status: any) {
    if (!status) {
      localStorage.setItem('notification_timer_status', 'stop');
    }
    setTimerStatus(status);
  }

  // Play sound when body vital in critical stage
  function toggleSound() {
    let audio = new Audio(require("../../assets/sounds/emergency_alarm.mp3").default);
    audio.play();
  }

  // on the basis of type return url of badge
  function returnUrlofBadge(type: any) {
    switch (type) {
      case 'affiliate':
        return SilverBadge;
      case 'gold affiliate':
        return GoldBadge;
      case 'xcellent affiliate':
        return DiamondBadge;
      case 'consumer':
        return ConsumerBadge;
      case 'buisness':
        return ConsumerBadge;
      case 'affiliate_bronze':
        return BronzeBadge;
      case 'affiliate_silver':
        return SilverBadge;
      default:
        return '';
    }
  }

  return (
    <header className="py-4 loginheader">
      <Container>
        <Row>
          <Col md={12}>
            <Navbar collapseOnSelect expand="lg" className="py-0">
              <Navbar.Brand >
                <Link to="/" className={window.location.pathname === "/" ? 'disabled' : ''}>
                  <img src={require("../../images/logo.png").default} alt="home" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                {
                  window.location.pathname === "/create_profile"
                    // || !isPlan
                    || window.location.pathname === "/membership/plan"
                    || plan_id ?
                    <Nav>
                      <a className="Register_btn" onClick={logout} href="#" >Logout</a>
                    </Nav>
                    :
                    <Nav>
                      {
                        LOGIN_HEADER_MENU.map((value, index) => {
                          return (
                            // className={value.url === "/alert"?metricCount?"mr-2":"":""}
                            <Nav.Item key={`${value.url}_${index}`}>
                              {
                                value.url === "/alert" ?
                                  metricCount ?
                                    <span className="position-relative d-inline-block">
                                      <Link to={value.url} className="text-white nav-link nx-2 d-flex align-items-center" onClick={handleMenuClick}>{value.title}<span className="count">{metricCount} </span></Link>
                                    </span>
                                    : <Link to={value.url} className={`${value.url === window.location.pathname ? `active_menu` : ``} text-white nav-link`} onClick={handleMenuClick}>{value.title}</Link>
                                  : <Link to={value.url} className={`${value.url === window.location.pathname ? `active_menu` : ``} text-white nav-link`} onClick={() => {

                                    if (value.title === "Home") {
                                      localStorage.setItem("left_menu_option", "first");
                                      // if (window.location.pathname === "/") {
                                      //   localStorage.setItem("left_menu_option", "first");
                                      //   window.location.reload();
                                      // } else {
                                      //   // localStorage.setItem("isReportClick", "true");
                                      //   window.location.href = "/";
                                      // }
                                    }
                                    if (value.title === "Reports") {
                                      localStorage.setItem("left_menu_option", "fifth");
                                      // if (window.location.pathname === "/") {
                                      //   localStorage.setItem("left_menu_option", "fifth");
                                      //   window.location.reload();
                                      // } else {
                                      //   localStorage.setItem("isReportClick", "true");
                                      //   window.location.href = "/";
                                      // }
                                    }
                                    handleMenuClick();
                                  }}>{value.title}</Link>

                              }
                            </Nav.Item>
                          )
                        })
                      }
                      <Link to="/chatlist" className="text-white nav-link" onClick={handleMenuClick}>
                        <span className="position-relative d-inline-block">
                          <img src={require('../../images/mail.svg').default} alt="mail" width="22px" />
                        </span>
                      </Link>
                      <Link to="/notification" className="text-white nav-link" onClick={handleMenuClick}>
                        <span className="position-relative d-inline-block">
                          <img src={require('../../images/notification.svg').default} alt="notification" width="22px" />
                          {badgeCount ? <span className="count">{badgeCount} </span> : ""}
                        </span>
                      </Link>
{console.log('setBadgeCount',badgeCount)}
                      {userName && userName != 'null null' ? (
                        <Nav.Link>
                          <Dropdown className="profile_1">
                            <Dropdown.Toggle variant="unset" id="dropdown-basic" className="p-0">
                              <div className="username_row1">
                                <span className="user_dropdown">
                                  {/* <img src={require('../../images/profile.jpeg').default} alt="" /> */}
                                  {profile && profile != 'null' ? (<img src={profile} alt="user_dropdown" onError={(event: any) => { event.target.src = ProfileImage; }} />) : (<img src={require('../../images/profile.jpeg').default} alt="profile" />)}
                                  {/* returnUrlofBadge(planDetail.plan.toLowerCase()) */}
                                  {/* {planDetail.plan?<img src={require('').default} className="profile-badge-header" alt="badge" onError={(event: any) => { event.target.src = ProfileImage; }}/>:''} */}
                                  {
                                    badgeUrl ? <img src={badgeUrl} className="profile-badge-header" alt="badge" onError={(event: any) => { event.target.src = ProfileImage; }} /> : ''
                                  }
                                </span>
                                <span className="profile_name1">
                                  <h6 className="mb-0 ml-2 text-white">{userName}</h6>
                                  <img src={require('../../images/dwn1.svg').default} alt="dwn1" width="12px" className="ml-1" />
                                </span>
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Link to="/profile" onClick={handleMenuClick}><img src={require('../../images/usericon.svg').default} alt="usericon" className="mr-2" />Profile</Link>
                              <Link to="/setting" onClick={handleMenuClick}><img src={require('../../images/settingicon.svg').default} alt="settingicon" className="mr-2" />Setting</Link>
                              <Dropdown.Item onClick={logout}><img src={require('../../images/logout.svg').default} alt="logout" className="mr-1" /> Logout</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Nav.Link>
                      ) : (<a className="Register_btn" onClick={logout} href="#" >Logout</a>)}
                      {/* <span className="login_btn" onClick={logout}>Logout</span> */}
                    </Nav>
                }
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>

      <Modal backdrop="static" centered show={showModal}>
        <Modal.Header>
          <Modal.Title>Incoming call</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            otherUser.calltype == 'G' ? <h5>{otherUser.caller_name} is calling in {otherUser.receiver_name}</h5> : <h5>{otherUser.caller_name} is calling you.</h5>
          }
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="success" className="px-4" onClick={acceptCall}>Accept</Button>
          <Button variant="danger" onClick={rejectCall} className="px-4 ml-4">Reject</Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" backdrop="static" centered show={showJitsiModal}>
        <Modal.Header>
          <Modal.Title>
            <h5>{otherUser.calltype === 'G' ? otherUser.receiver_name : otherUser.caller_name}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JitsiMeeting configOverwrite={jitsiConfig} roomName={jitsiRoom} userInfo={{ displayName: userName, email: "" }} getIFrameRef={handleJitsiIFrameRef} domain="meet.jit.si" onApiReady={externalApi => handleApiReady(externalApi, apiRef)} />
        </Modal.Body>
      </Modal>

      <Modal show={shareModal} onHide={() => { setShareModal(false); toggleTimer(false); }} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className="border-0 p-1 pb-0 pr-3 pt-3">
          <Modal.Title />
        </Modal.Header>
        <Modal.Body className="notification_modal pt-0">
          <h5>
            Share Message
          </h5>
          <p>You can share {shareData.group_name} ad.</p>
          <div className="my-4">
            <div style={{ width: 180, height: 180 }} className="m-auto">
              <CountdownCircleTimer duration={timer} isPlaying={timerStatus} colors={[["#004777", 0.33]]} >
                {readTime}
              </CountdownCircleTimer>
            </div>
          </div>
          {/* <div className="modal_img_row m-auto">
            {
              shareData.ad_images ? <img src={shareData.ad_images[0].attachment_url} alt="imagetoshow" onError={(event: any) => { event.target.src = ProfileImage; }} /> : ""
            }
          </div> */}

          {/* IMPLEMENTED TOMMOROW */}
          {shareData.ad_images ? <div className="container">
            <div className="row justify-content-center">
              {
                shareData.ad_images.map((value: any, index: Number) => {
                  let extension = value.attachment_url.split('/').pop().split('.').pop();
                  let attachmentObject = value as any;
                  if (IMAGE_REGEX.test(`.${extension}`)) {
                  } else {
                    attachmentObject.notification_attachement_id = `${value.id}&${value.id}`;
                  }
                  return (
                    <div className={`col-md-3 pl-2 pr-2 pb-2 pt-2 ${parseInt(shareURL.id) === value.id ? "select-image" : ""}`} key={`${value.id}_${index}`} id={value.id} onClick={() => handleSocialShareUrl(value)} style={{ position: "relative" }}>
                      {
                        IMAGE_REGEX.test(`.${extension}`) ?
                          <img src={value.attachment_url} alt={value.attachment_url} className="comp_ad_media" /> :
                          <>
                            <i className="fa fa-play-circle play-bt-vid" aria-hidden="true" />
                            <video src={value.attachment_url} autoPlay={false} controls={false} className="thumb_video" />
                          </>
                      }
                    </div>
                  )
                })
              }
            </div>
          </div> : ''}

          <div className="invite_users_links mt-4">
            <Link to="#/" className="mx-1">
              <FacebookShareButton
                onShareWindowClose={(status: any) => { socialShare("F", timer) }}
                beforeOnClick={() => {
                  toggleTimer(false);
                }}
                url={window.location.origin + '/competitiondetails?comp_id=' + shareData.competition_id}
                quote={shareData.group_name}>
                <img src={require('../../images/invite_fb.svg').default} alt="invite_fb" />
              </FacebookShareButton>
            </Link>
            <Link to="#/" className="mx-1">
              <TwitterShareButton
                beforeOnClick={() => {
                  toggleTimer(false);
                }}
                onShareWindowClose={(e: any) => {
                  socialShare("T", timer)
                }}
                title={"Here is the link to " + shareData.group_name + " on XcellentLife."}
                url={window.location.origin + '/competitiondetails?comp_id=' + shareData.competition_id}>
                <img src={require('../../images/invite_twitter.svg').default} alt="invite_twitter" />
              </TwitterShareButton>
            </Link>
            <Link to="#/" className="mx-1">
              <LinkedinShareButton
                onShareWindowClose={() => { socialShare("L", timer) }}
                beforeOnClick={() => {
                  toggleTimer(false);
                }}
                title={"Here is the link to " + shareData.group_name + " on XcellentLife."}
                url={`${window.location.origin}/competitiondetails?comp_id=${shareData.competition_id}`}>
                <img src={require('../../images/invite_link.svg').default} alt="invite_link" />
              </LinkedinShareButton>
            </Link>
          </div>
          {/* <button className="btn sharebtn text-white mt-4">SHARE</button> */}
          <button className="btn sharebtn text-white mb-4" onClick={() => { setShareModal(false) }}>DECLINE</button>
        </Modal.Body>
      </Modal>
    </header>
  )
}

export default LoginHeader;