import { useEffect, useState } from 'react'
import { Container, Button } from 'react-bootstrap'
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { userService } from '../_services';
import { Loader } from './Loader';

const JoinedInviteGroup = () => {
    const [data, setData] = useState({}) as any
    const [show, setShow] = useState("none");
    const [refer, setRefer] = useState("");

    let history = useHistory();
    let user_id = localStorage.getItem("user_id");

    useEffect(() => {
        triggerLoader("flex");
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let group_id = '' as any;
        let userId = params.get('user_id');
        userId = userId ? userId.replace('+', '') : '';
        if (window.location.pathname === "/live_group") {
            // check if user id is matched with loggedin user
            if (user_id === userId) {
                if (params.get('id')) {
                    group_id = params.get('id');
                }
                if (params.get('group_id')) {
                    group_id = params.get('group_id');
                }
                groupDetail(group_id);
            } else {
                toast.error('You are not authorized to join this group.');
                history.push(`/`);
            }
        } else {
            if (params.get('id')) {
                group_id = params.get('id');
            }
            groupDetail(group_id);
        }
    }, []);


    function groupDetail(group_id: any) {
        // check if user is already joined  then redirected to group detail page else user will join group
        userService.groupDetail({ "group_id": group_id }).then(resp => {
            if (resp.data.status == true) {
                let respData = resp.data.data;
                if (respData.status == 'J') {
                    // currently fixing viaIsDetail
                    localStorage.setItem('viaIsDetail', "true");

                    history.push(`/invite_group?id=${group_id}`);
                    // localStorage.setItem("groupTab", "first");
                    // history.push("/group");
                }
                setData(respData);
                triggerLoader("none")
            } else {
                toast.error(resp.data.message)
                triggerLoader("none")
            }
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toast.error('Error while loading group details.')
            triggerLoader("none")
        })
    }

    // on click on join group first we check if group join amount is greater than 0 then we redirect to payment page otherwise we call api to join group
    function handleSubmit() {
        triggerLoader("flex");
       
        if (data.amount) {
            if (data.amount == 0) {
                joinGroup();
            } else {
                let params = {
                    "user_id": data.created_id,
                    "payer_id": user_id,
                    "event_id": data.group_id,
                    "amount": data.amount,
                    "default_amount": data.joining_fee,
                    "payment_type": "G",
                    "discount_code": refer,
                    "currency": data.your_currency,
                    "payment_id": ""
                };
                localStorage.setItem("paymentDetails", JSON.stringify(params));
                history.push(`/payment?group_id=${data.group_id}&amount=${data.amount}${refer ? `&refer_code=${refer}` : ''}`);
            }
        } else {
            joinGroup();
        }
    }

    // join group API
    function joinGroup() {
        userService.joinGroup({ "group_id": data.group_id }).then(resp => {
            triggerLoader("none");
            if (resp.data.status) {
                toast.success(resp.data.message);
                localStorage.setItem("groupTab", "first");
                history.push("/group");
            } else {
                toast.error(resp.data.message);
            }
        }).catch(err => {
            if (err.response) {
                toast.error(err.response.data.message);
            }
        })
    }

    // common function to trigger loader
    function triggerLoader(status: any) {
        setShow(status)
    }

    console.log('data.joining_fee', data.joining_fee)
    let totalAmt = data.joining_fee === "null" ? 0 : data.joining_fee ? data.amount.toString().length === 3 ? `${data.amount}0` : `${data.amount}.00` : 0

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={show} />
                <Container>
                    <div className="inner_box pb-4">
                        <div className="row bodyvitals_head">
                            <div className="col-md-12">
                                <h5>
                                    <span onClick={() => { history.go(-1) }}>
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </span>
                                </h5>
                                <h2>{data.group_name}</h2>
                            </div>
                            <div className="col-md-12"><hr /></div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <div className="gorup_Details_head">
                                    <div className="user_image_row1">
                                        <img src={data.group_image} alt="gorup_Details_head" />
                                    </div>
                                    <h5>Created By: <span key="1">{data.created_id == user_id ? "Me" : data.created_by}</span></h5>
                                </div>
                                <div className="Description_row mt-3">
                                    <h5>Description:</h5>
                                    <p>{data.description}</p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="gorup_Details_head group_list_row1 d-block">
                                    {
                                        data.joining_fee ?
                                            <h5 className="d-block ml-0 mb-1">Joining fee: {data.amount == 0 ? <span>Free</span> : <span>{data.your_currency_symbol}{`${totalAmt}`}</span>}</h5>
                                            : ""
                                    }

                                    {/* {data.amount != '0'?
                                        <div className="mt-3">
                                            <Form.Group className="mb-3" controlId="referralCode">
                                                <Form.Label>Referral Code:</Form.Label>
                                                <Form.Control type="text" placeholder="Enter referral code" onChange={(e: any) => setRefer(e.target.value)} value={refer} />
                                            </Form.Group>
                                        </div>: ""} */}
                                    <div><Button variant="unset" onClick={handleSubmit} className="btnsumit mt-2 text-white">Join Group</Button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default JoinedInviteGroup;