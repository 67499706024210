import { useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap'
import DatePicker from 'react-date-picker';
import { toast } from 'react-toastify';
import { userService } from '../_services';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Loader } from './Loader';

const Connect = () => {
    const [source, setSource] = useState("")
    const [show, setShow] = useState("flex");
    //    const [fitbitData, setFitbitData] = useState({}) as Array<any>;
    const [showGarmin, setShowGarmin] = useState(false);
    const [garminData, setGarminData] = useState({}) as any;
    const [showFitbit, setShowFitbit] = useState(false);
    const [valDate, setValDate] = useState("") as any;
    const [allFitBitData, setAllFitBitData] = useState({}) as any;
    const ONE_KM_IN_MILES = 0.6213727366498067;

    const fitBitObject = {
        calorie_ingested: "",
        distance_walked: "",
        heart_rate: "",
        steps_taken: ""
    } as any;

    const garminObject = {
        calorie_ingested: '',
        distance_walked: '',
        steps_taken: '',
        stress: '',
        heart_rate: ''
    } as any;

    const history = useHistory();

    const fitBitGarminId = {
        height: 42,
        glucose_level: 8,
        sleep: 17,
        stress: 18,
        calorie_expended: 21,
        calorie_ingested: 21,
        distance_walked: 23,
        run: 23,
        steps_taken: 24,
        water_consumption: 25,
        heart_rate: 52,
        weight: 43,
    } as any;

    const fitbitGarminUnit = {
        "height": "Inches",
        "glucose_level": "mg/dL",
        "sleep": "hours/day",
        "stress": "rate",
        "calorie_expended": "calories/day",
        "calorie_ingested": "calories/day",
        "distance_walked": "miles",
        "run": "miles",
        "steps_taken": "Steps/day",
        "water_consumption": "oz of water",
        "heart_rate": "bpm",
        "weight": "Pounds"
    } as any;

    useEffect(() => {
        let windowHash = window.location.hash;
        if (windowHash) {
            var token_hash = windowHash.split("&")[0].split("=")[1];
            var fitbit_user_id = windowHash.split("&")[1].split("=")[1];
            localStorage.setItem('fitbitToken', token_hash);
            localStorage.setItem('fitbitId', fitbit_user_id);
            toast.success("Fitbit connected successfully!");
            getFitBitHeartData();
            let data = { access_token: token_hash };
            saveFitBitAccessToken(data);
        } else {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let source = params.get('source') as any;
            let oauth_verifier = params.get('oauth_verifier') as any;
            let secret = localStorage.getItem('garminauthSecret') as any;
            let garminToken = localStorage.getItem('garminauthToken') as any;
            setSource(source);
            if (oauth_verifier) {
                userService.getGarminAccessToken(garminToken, secret, oauth_verifier).then((resp: any) => {
                    if (resp.data.data.errorMessage) {
                        localStorage.removeItem('garminauthSecret');
                        localStorage.removeItem('garminauthToken');
                        toast.error("An error occured");
                    } else {
                        var temp = resp.data.data;
                        localStorage.setItem('garminauthSecret', temp.oauth_token);
                        localStorage.setItem('garminauthToken', temp.oauth_token_secret);
                        toast.success('Garmin connected successfully!');
                    }
                    toggleLoaderStatus("none");
                }).catch(err => {
                    if (err.response.status == 401) {
                        localStorage.clear();
                        window.location.href = '/';
                    }
                    toggleLoaderStatus("none");
                })
            } else {
                toggleLoaderStatus("none");
            }
        }

    }, [])

    function connectgarmin() {
        toggleLoaderStatus("flex");
        userService.getGarminToken().then(resp => {
            toggleLoaderStatus("none");
            var token = resp.data.data.oauth_token;
            localStorage.setItem('garminauthToken', resp.data.data.oauth_token);
            localStorage.setItem('garminauthSecret', resp.data.data.oauth_token_secret);
            var redirect = window.location.href;
            window.location.href = `https://connect.garmin.com/oauthConfirm?oauth_token=${token}&oauth_callback=${redirect}`;
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            toggleLoaderStatus("none");
            toast.dismiss();
            toast.error('An error occured.');
        })
    }

    function updateGarminData() {
        toggleLoaderStatus("flex")
        userService.getGarminHealthData({ oauth_token: localStorage.getItem('garminauthSecret'), oauth_token_secret: localStorage.getItem('garminauthToken') })
            .then(resp => {
                toggleLoaderStatus("none");
                console.log('resp.data',resp.data)
                console.log(resp.data.data)
                if (resp.data.data.errorMessage == 'oauth_token (UserAccessToken) missing') {
                    toast.error('Please reconnect with garmin.');
                    setShowGarmin(false);
                    return;
                }
                if (resp.data.data.errorMessage) {
                    toast.error('Please sync your latest data with Garmin server.');
                    setShowGarmin(false);
                    return;
                }
                if (resp.data.data.length == 0) {
                    toast.error('Please update your Garmin app data.');
                    setShowGarmin(false);
                    return;
                } else {
                    var el = {} as any
                    for (let index = 0; index < resp.data.data.length; index++) {
                        const element = resp.data.data[index];
                        if (index == resp.data.data.length - 1) {
                            el.calendarDate = element.calendarDate;
                            el.activeKilocalories = element.activeKilocalories;
                            el.distanceInMeters = ((parseFloat(element.distanceInMeters) / 1000) * ONE_KM_IN_MILES);
                            // distance=(distance/1000)*ONE_KM_IN_MILES;
                            el.averageHeartRateInBeatsPerMinute = element.averageHeartRateInBeatsPerMinute;
                            el.steps = element.steps;
                            el.averageStressLevel = element.averageStressLevel % 20 ? (element.averageStressLevel / 20).toFixed(2) : element.averageStressLevel / 20;
                        }
                    }
                    setShowGarmin(true);
                    setGarminData(el);
                    return;
                }
            })
            .catch(err => {
                toggleLoaderStatus("none");
                if (err.response.status == 401) {
                    localStorage.clear();
                    window.location.href = '/';
                }
            })
    }

    function syncDataFitBit() {
        toggleLoaderStatus("flex");
        var todayDate = new Date;
        var today = todayDate.getFullYear() + "-" + todayDate.getMonth() + "-" + todayDate.getDate();
        userService.getFitBitData(today).then(resp => {
            toggleLoaderStatus("none");
            if(resp.data.summary){
                // handleStateOfFitBitData("steps", resp.data.summary.steps);
                // handleStateOfFitBitData("distance", resp.data.summary.distances[0].distance);
                // handleStateOfFitBitData("calories", resp.data.summary.caloriesOut);
                // handleStateOfFitBitData("heart_rate", resp.data.summary.restingHeartRate);
                if(resp.data.summary.steps){
                    handleStateOfFitBitData("steps", resp.data.summary.steps);
                }else{
                    handleStateOfFitBitData("steps", 0);
                }
                if(resp.data.summary.distances.length){
                    handleStateOfFitBitData("distance", resp.data.summary.distances[0].distance);
                }else{
                    handleStateOfFitBitData("distance", 0);
                }
                if(resp.data.summary.caloriesOut){
                    handleStateOfFitBitData("calories", resp.data.summary.caloriesOut);
                }else{
                    handleStateOfFitBitData("calories", 0);
                }
                if(resp.data.summary.restingHeartRate){
                    handleStateOfFitBitData("heart_rate", resp.data.summary.restingHeartRate);
                }else{
                    handleStateOfFitBitData("heart_rate", 0);
                }
                setShowFitbit(true);
            }else{
                setShowFitbit(false);
            }

            // setFitbitData(resp.data.summary)
        }).catch(err => {
            toggleLoaderStatus("none")
            if(err){
                if (err.response.status == 401) {
                    toast.error('Please connect with fitbit');
                } else {
                    toast.error("An error occured");
                }
            }else {
                toast.error("An error occured");
            }
        })
    }

    function connectFitbit() {
        var fitURI = `https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=${process.env.REACT_APP_FITBIT_KEY}&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_FIT_BIT_CALL_BACK_URL}`)}&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800`;
        toggleLoaderStatus("flex");
        window.location.href = fitURI;
    }

    // load data of fitbit access token
    function saveFitBitAccessToken(data: any) {
        userService.saveFitBitToken(data).then(resp => {

        }).catch(err => {
            toggleLoaderStatus("none")
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit');
            } else {
                toast.error("An error occured");
            }
        })

    }
    // load data of fitbit heart 
    function getFitBitHeartData() {
        let todayDate = returnTodayDate();
        userService.getFitBitHeartData(todayDate).then(resp => {
            let heartRate = 0;
            if (resp.data.categories.length) {
                resp.data.categories.forEach((value: any) => {
                    if (value.activities.length) {
                        value.activities.forEach((data: any) => {
                            if (data.mets) {
                                heartRate += data.mets;
                                console.log('data.mets',data.mets);
                                console.log('data.mets heartRate',heartRate);
                            }
                            if (data.activityLevels) {
                                data.activityLevels.forEach((activityLevels: any) => {
                                    heartRate += activityLevels.mets;
                                    console.log('activityLevels.mets',activityLevels.mets);
                                    console.log('activityLevels.mets heartRate',heartRate);

                                })
                            }
                        });
                    }
                });
                handleStateOfFitBitData("heart_rate", heartRate);
                getFitBitSleepData();
            }
        }).catch(err => {
            toggleLoaderStatus("none");
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit');
            } else {
                toast.error("An error occured");
            }
        })
    }

    // get fitbit sleep data
    function getFitBitSleepData() {
        let todayDate = returnTodayDate();
        userService.getFitBitSleepData(todayDate).then(resp => {
            // toggleLoaderStatus("none")
            setShowFitbit(true);
            // setFitbitData(resp.data.summary)
            getFitBitDistanceData();
        }).catch(err => {
            toggleLoaderStatus("none")
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit');
            } else {
                toast.error("An error occured");
            }
        })
    }
    // load data of distance travelled
    function getFitBitDistanceData() {
        let todayDate = returnTodayDate();
        userService.getFitBitDistanceData(todayDate).then(resp => {
            let distance = 0;
            Object.keys(resp.data).forEach((value: any) => {
                resp.data[value].forEach((data: any) => {
                    distance += parseFloat(data.value);
                });
            });
            if (distance) {
                distance = (distance / 1000) * ONE_KM_IN_MILES;
            }
            handleStateOfFitBitData("distance", distance);
            getFitBitGlucoseData();
        }).catch(err => {
            toggleLoaderStatus("none");
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit');
            } else {
                toast.error("An error occured");
            }
        })
    }
    // load data of glucose 
    function getFitBitGlucoseData() {
        let todayDate = returnTodayDate();
        userService.getFitBitGlucoseData(todayDate).then(resp => {
            console.log('getFitBitGlucoseData response', resp.data);
            getFitBitFoodLogWaterData();
        }).catch(err => {
            toggleLoaderStatus("none");
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit');
            } else {
                toast.error("An error occured");
            }
        })
    }
    // load data of log water
    function getFitBitFoodLogWaterData() {
        let todayDate = returnTodayDate();
        userService.getFitBitFoodLogWaterData(todayDate).then(resp => {
            console.log('getFitBitFoodLogWaterData response', resp.data);
            getFitBitStepsData();
        }).catch(err => {
            toggleLoaderStatus("none");
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit');
            } else {
                toast.error("An error occured");
            }
        })
    }
    // load data of fitbit step data
    function getFitBitStepsData() {
        let todayDate = returnTodayDate();
        userService.getFitBitStepsData(todayDate).then(resp => {
            console.log('getFitBitStepsData response', resp.data);
            let steps = 0;
            Object.keys(resp.data).forEach((value: any) => {
                resp.data[value].forEach((data: any) => {
                    steps += parseInt(data.value);
                });
            });
            handleStateOfFitBitData("steps", steps);
            getFitBitCaloriesData();
        }).catch(err => {
            toggleLoaderStatus("none");
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit');
            } else {
                toast.error("An error occured");
            }
        })
    }

    // load data fitbit calorie data
    function getFitBitCaloriesData() {
        let todayDate = returnTodayDate();
        userService.getFitBitCaloriesData(todayDate).then(resp => {
            console.log('getFitBitCaloriesData response', resp.data);
            let calories = 0;
            Object.keys(resp.data).forEach((value: any) => {
                resp.data[value].forEach((data: any) => {
                    calories += parseInt(data.value);
                });
            });
            handleStateOfFitBitData("calories", calories);
            getFitBitBodyLogWieghtData();
        }).catch(err => {
            toggleLoaderStatus("none");
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit');
            } else {
                toast.error("An error occured");
            }
        })
    }
    // load data of fit bit body log weight
    function getFitBitBodyLogWieghtData() {
        let todayDate = returnTodayDate();
        userService.getFitBitBodyLogWieghtData(todayDate).then(resp => {
            console.log('getFitBitBodyLogWieghtData response', resp.data);
            toggleLoaderStatus("none");
        }).catch(err => {
            toggleLoaderStatus("none");
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit');
            } else {
                toast.error("An error occured");
            }
        })
    }

    // return today date in YYYY-MM-DD formate
    function returnTodayDate() {
        let todayDate = new Date();
        let month = todayDate.getMonth() as any;
        month = parseInt(month) + 1;
        month = parseInt(month) < 10 ? `0${month}` : month;
        let date = todayDate.getDate() as any;
        date = parseInt(date) < 10 ? `0${date}` : date;
        let today = todayDate.getFullYear() + "-" + month + "-" + date;
        return today;
    }

    // on trigger of date input field load entries on the base of updated
    function handleDate(e: any) {
        var date = new Date(e);
        var dt = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
        setValDate(e);
        toggleLoaderStatus("flex");
        userService.getFitBitData(dt).then(resp => {
            toggleLoaderStatus("none");
            if(resp.data.summary){
                if(resp.data.summary.steps){
                    handleStateOfFitBitData("steps", resp.data.summary.steps);
                }else{
                    handleStateOfFitBitData("steps", 0);
                }
                if(resp.data.summary.distances.length){
                    handleStateOfFitBitData("distance", resp.data.summary.distances[0].distance);
                }else{
                    handleStateOfFitBitData("distance", 0);
                }
                if(resp.data.summary.caloriesOut){
                    handleStateOfFitBitData("calories", resp.data.summary.caloriesOut);
                }else{
                    handleStateOfFitBitData("calories", 0);
                }
                if(resp.data.summary.restingHeartRate){
                    handleStateOfFitBitData("heart_rate", resp.data.summary.restingHeartRate);
                }else{
                    handleStateOfFitBitData("heart_rate", 0);
                }
                setShowFitbit(true);
            }else{
                setShowFitbit(false);
            }
        }).catch(err => {
            if (err.response.status == 401) {
                toast.error('Please connect with fitbit.');
            }
        })
    }

    // common function to handle state of fitbit data
    function handleStateOfFitBitData(type: string, value: any) {
        setAllFitBitData((prevAllFitBitData: any) => {
            return {
                ...prevAllFitBitData,
                [type]:value === ""? "": value.toFixed(2)
            }
        });
    }

    // toggle loader status i.e either flex or none
    function toggleLoaderStatus(status: any) {
        setShow(status);
    }

    // handle update of fitbit and garmin response
    function handleFitBitGraminUpdate(params: any, type: string) {
        let object = {} as any, idArray = [] as any, valuesArray = [] as any, unitsArray = [] as any;
        if (type === "fitbit") {
            object = fitBitObject;
            object.calorie_ingested = params.calories;
            object.distance_walked = params.distance;
            object.heart_rate = params.heart_rate;
            object.steps_taken = params.steps;
        } else {
            object = garminObject;
            object.calorie_ingested = params.activeKilocalories;
            object.distance_walked = params.distanceInMeters;
            object.steps_taken = params.steps;
            object.stress = params.averageStressLevel;
            object.heart_rate = params.averageHeartRateInBeatsPerMinute;
        }
        Object.keys(object).forEach((value: any) => {
            idArray.push(fitBitGarminId[value]);
            valuesArray.push(object[value]);
            unitsArray.push(fitbitGarminUnit[value]);
        });
        let data: any = {
            // ...object,
            date_measured: moment().format('YYYY-MM-DD'),
            ids: idArray,
            source: type,
            units: unitsArray,
            user_id: localStorage.getItem('user_id'),
            values: valuesArray,
        };
        userService.updateFitbitGarminData(data).then(response => { return response.data })
            .then((result) => {
                toggleLoaderStatus("none");
                toast.success('Record updated successfully.');
                history.push('/');
            })
            .catch(error => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    window.location.href = '/';
                }
                toggleLoaderStatus("none");
            });
    }

    return (
        <>
            <section className="profile-page py-5">
                <Loader status={show} />
                <div className="container">
                    <div className="inner_box">
                        <Row className="bodyvitals_head">
                            <Col md={12}>
                                <h5>
                                    <a href="/"><img src={require('../images/back.svg').default} alt="back" /></a>
                                </h5>
                                <h4>Connect your device</h4>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                            <Col md={12} className="text-center">
                                {source == 'garmin'
                                    ?
                                    <>
                                        <img height="120px" src={require('../images/Garmin-logo.png').default} alt="Garmin" />
                                        <button className="btn fitbtn active" onClick={connectgarmin}>CONNECT WITH GARMIN</button>
                                        <button className="btn fitbtn active" onClick={updateGarminData} >SYNC DATA FROM GARMIN</button>
                                        <div className="col-md-6 offset-3">
                                            {
                                                showGarmin
                                                    ?
                                                    <>
                                                        <p>Date measured</p>
                                                        <DatePicker value={new Date} format="MM-dd-y" disabled />
                                                        <Table>
                                                            <tbody>
                                                                {garminData.activeKilocalories ? <tr>
                                                                    <td className="d-flex align-left border-0 text-primary">Calorie expanded</td>
                                                                    <td className="border-0">{garminData ? garminData.activeKilocalories : ""}</td>
                                                                </tr> : ''}
                                                                {garminData.distanceInMeters ? <tr>
                                                                    <td className="d-flex align-left text-primary">Distance walked (Miles)</td>
                                                                    <td>{garminData ? garminData.distanceInMeters : ""}</td>
                                                                </tr> : ''}
                                                                {garminData.averageHeartRateInBeatsPerMinute ? <tr>
                                                                    <td className="d-flex align-left text-primary">Heart rate (per minute)</td>
                                                                    <td>{garminData ? garminData.averageHeartRateInBeatsPerMinute : ""}</td>
                                                                </tr> : ''}
                                                                {garminData.steps ? <tr>
                                                                    <td className="d-flex align-left text-primary">Steps taken</td>
                                                                    <td>{garminData ? garminData.steps : ""}</td>
                                                                </tr> : ''}
                                                                {garminData.averageStressLevel ? <tr>
                                                                    <td className="d-flex align-left text-primary">Stress Level</td>
                                                                    <td>{garminData ? garminData.averageStressLevel : ""}</td>
                                                                </tr> : ''}
                                                            </tbody>
                                                        </Table>
                                                        <button className="btn fitbtn mt-2" onClick={() => {
                                                            toggleLoaderStatus("flex");
                                                            handleFitBitGraminUpdate(garminData, 'garmin');
                                                        }}>Update</button>
                                                    </>
                                                    : ""
                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                        <img src={require('../images/fit.png').default} alt="fitbit" />
                                        <button className="btn fitbtn active" onClick={connectFitbit}>CONNECT WITH FITBIT</button>
                                        <button className="btn fitbtn active" onClick={syncDataFitBit}>SYNC DATA FROM FITBIT</button>
                                        {
                                            showFitbit
                                                ?
                                                <>
                                                    <div className="col-md-6 offset-3">
                                                        <p>Date measured</p>
                                                        <DatePicker value={valDate ? valDate : new Date} format="MM-dd-y" maxDate={new Date} onClickDay={handleDate} />
                                                        <Table>
                                                            <tbody>
                                                                {allFitBitData.calories ? <tr>
                                                                    <td className="d-flex align-left border-0 text-primary">Calorie Expanded</td>
                                                                    <td>{allFitBitData.calories ? allFitBitData.calories : 0}</td>
                                                                </tr> : ''}
                                                                {allFitBitData.distance ? <tr>
                                                                    <td className="d-flex align-left text-primary">Distance Walked in (Miles)</td>
                                                                    <td>{allFitBitData.distance ? allFitBitData.distance : 0}</td>
                                                                </tr> : ''}
                                                                {allFitBitData.heart_rate ? <tr>
                                                                    <td className="d-flex align-left text-primary">Heart Rate</td>
                                                                    <td>{allFitBitData.heart_rate ? allFitBitData.heart_rate : 0}</td>
                                                                </tr> : ''}
                                                                {allFitBitData.steps ? <tr>
                                                                    <td className="d-flex align-left text-primary">Steps taken</td>
                                                                    <td>{allFitBitData.steps ? allFitBitData.steps : 0}</td>
                                                                </tr> : ''}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <button className="btn fitbtn mt-2 active" onClick={() => {
                                                        toggleLoaderStatus("flex");
                                                        handleFitBitGraminUpdate(allFitBitData, 'fitbit');
                                                    }}>Update</button>
                                                </>
                                                : ""
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Connect;